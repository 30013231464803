@media only screen and (max-width: 600px) {
  /* .dm-btn2 {
    white-space: nowrap !important;
    font-size: 10px;
  } */
}

.checkbox {
  /* margin-left: 90px; */
  text-align: right;
}

.rejectInput {
  height: 48px;
  width: 100%;
  /* margin-top: 5px; */
  margin-bottom: 18px;
  background: rgba(202, 202, 202, 0.1);
  border: none;
}

.verify-model-span {
  margin-top: 50px;
}
.verify-img {
  height: 88px;
  width: 133px;
  margin-left: 20px;
  margin-top: 30px;
}

.verify-model-info {
  margin-left: 20px;
  margin-right: 64px;
}
.verify-detail-header {
  margin-left: 20px;
  margin-top: 17px;
  margin-bottom: 12px;
}
.verify-info-heading h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #999999;
  font-size: 14px;
}
.verify-billing-heading h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}
.verify-info-last h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}
.verify-info-amount h6 {
  float: right;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.verify-line {
  margin-left: 20px;
  margin-right: 64px !important;
  width: 690px;
}
.verify-cancel-btn {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  width: 125px;
  height: 45px;
  background: #999999;
  border-radius: 5px;
  border: none;
}
.verify-model-btns {
  margin-left: 20px;
  margin-top: 32px;
}
.verify-model-btn {
  margin-left: 20px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  width: 125px;
  height: 45px;
  background: rgba(53, 215, 161, 0.95);
  border-radius: 5px;
  border: none;
}
.verify-Cancel {
  margin-left: 40px;
}

.verify-col-info h6 {
  float: right;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  color: #333333;
}
.verify-detail-header span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #333333;
}
.verify-model-span span {
  float: right;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
}
.verify-model-span h4 {
  float: right;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #000000;
}

.verify-pdf-btn {
  width: 144px;
  height: 33px;
  float: right;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  background: #333333;
  border-radius: 10px;
  border: none;
}

/* label:last-of-type {
  margin-right: 0;
} */

/* input[type="radio"]:hover:not(:checked) + label {
  background-color: #35d7a1;
  color: white;
} */

.close-btn {
  width: 5px;
  height: 5px;
}
.dm-textarea {
  resize: none;
  height: 80px;
}

.d-body {
  font-family: "Montserrat";
  font-style: normal;
}

/* .dm-btn2 {
  border-radius: 4px;
  background-color: #000000;
  border: none;
  width: 206px;
  font-weight: 600;
  height: 45px;
} */

@media only screen and (max-width: 600px) {
  .verify-pdf-btn {
    width: 120px;
  }
  .verify-Cancel {
    margin-left: 10px;
  }
  .verify-model-span span {
    font-size: 12px;
    margin-left: 10px !important;
  }
  .verify-model-span h4 {
    font-size: 16px;
    font-weight: 700;
  }
  .verify-img {
    height: 80px;
    width: 80px;
    margin-left: 20px;
    margin-top: 30px;
  }
  .verify-line {
    width: 330px;
  }
  .verify-model-info {
    white-space: nowrap;
    /* margin-left: 20px; */
    margin-right: 30px;
  }
  .verify-model-btns {
    margin-left: 40px;
  }
  .verify-model-btn {
    margin-left: -10px;
  }
}
