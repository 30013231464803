.add-memeber-card {
  padding-bottom: 30px;
}
.add-member-col {
  padding-top: 40px;
  padding-left: 15px;
}
.add-member-col h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
}
.add-member-field {
  height: 56px;
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 5px;
}
.member-field-col h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.member-field-col {
  padding: 10px 15px;
}
.permisson-col {
  padding-top: 20px;
  padding-left: 15px;
}
.permission-cards {
  height: 100%;
  width: 100%;
  border: none;
  padding: 15px;
  background: #f5f5f5;
  border-radius: 10px;
}
.input-check-permision {
  border: 2px solid #000000;
}
.permission-cards h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
.permission-cards span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}
.permision-card-col {
  padding: 10px 15px;
}

.permisson-col h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
}
.add-user-btn {
  background: rgba(53, 215, 161, 0.95);
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 45px;
}
.card-btn-col {
  align-self: flex-end;
  padding: 10px 15px;
  height: 100%;
}

.member-field-col .required:after {
  content: " *";
  color: red;
}

.userRoeleye {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  padding-left: 15px;
  background: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  color: 333333;
  height: 53px;
  width: 45px;
  right: 415px;
  top: 237px;
}

@media only screen and (max-width: 600px) {
  .userRoeleye {
    right: 33px;
    top: 339px;
  }
}
