.nav-header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}
.mark-all {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.noti-icon {
  border: none;
}
.nav-links {
  /* border-bottom: 2px solid gray; */
  display: flex;
  /* justify-content: space-around; */
}
.notification-dropdownMenu {
  /* border: none;
  inset: 0px -133px auto auto !important; */
  /* edited by dawood  */
  border: none;
  inset: auto -155px auto auto !important;
  max-width: 400px;
position: absolute;
}

.noti-nav-icon {
  top: 2px;
  right: 2px;
  position: absolute;
  cursor: pointer;
  width: 24px;
  height: 21px;
}

.nav-containers {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #d9d9d9;
}
.nav-containers span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #999999;
}
.nav-containers1 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  align-items: center;
  border-bottom: 2px solid #0129fa;
}
.nav-containers1 span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #333333;
}
.nav-containers1 span span {
  padding-right: 4px;
  padding-left: 4px;
  background-color: #0129fa;
  color: #fff;
  border-radius: 2px;
}

.notification_nav_links {
  border-bottom: 5px solid #cccccc !important;
}

.notification-container span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.notification-item {
  text-decoration: none;
  display: flex;
  justify-content: start;
  padding: 0 10%;
  /* padding-top: 23px; */
  /* padding-bottom: 20px; */
  /* border-bottom: 2px solid gray;
  border-radius: 10px; */
}
.notification-container p span {
  font-weight: 600;
}
.notification-container p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #999999;
}

.shimmerLoader {
  padding-left: 40px;
}

.shimmerContainer {
  padding-left: 50px;
  height: 11px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .notification-dropdownMenu {
    border: none;
    inset: 0px auto auto auto !important;
  }
}
