.member-field-col .durationInput span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.boldClass {
  font-weight: bold;
}

.holidayRemoveBtn {
  height: 48px;
  margin-right: 20px;
  background: #ef4050;
  border: 1px solid #000000;
  /* color: #000000; */
  line-height: 1;
  border-radius: 4px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  --bs-btn-hover-border-color: none;
  --bs-btn-hover-bg: #ef4050;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-focus-shadow-rgb: none;
}
