.lgMain {
  background-color: #000000;
}
.loginImg {
  height: 100vh;
}

.loginCard {
  margin-top: 165px;
}

.loginCardDisplay {
  display: none;
}

/* .forgotPasswordCardDisplay {
  display: none;
} */

.lgCard {
  padding-top: 36px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 36px;
}

.forgetPasswordCard {
  padding-top: 36px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 50px;
}

.lgCard h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  padding-bottom: 20px;
}

.forgetPasswordCard h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  padding-bottom: 20px;
}
.forgetPasswordCard p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.forgetPasswordCard h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

/* login => lg */
.lgContainer {
  /* top: 0;
  left: 0;
  bottom: 0;
  right: 0;*/
  min-height: 100vh;
}

.lgContainerFluid {
  padding-left: 0%;
  padding-top: 1.2vmin;
  background-color: #172659;
  color: #ffffff;
  /* height: 100vh; */
}

.validate {
  border: #ef4050 solid 1px;
  margin-bottom: 18px;
  height: 50px;
  width: 100%;
  border-radius: 4px;
}
.validate-msg {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 125%; */
  margin-bottom: 0;
  color: #ef4050;

  /* margin-top: 20px; */
}

.validate-msg span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  text-decoration: underline;
  font-size: 12px !important;
  /* line-height: 125%; */
  margin-bottom: 0;
  color: #ef4050 !important;

  /* margin-top: 20px; */
}

.validate-msg1 {
  opacity: 0;
  margin-bottom: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 5%;
  color: #ef4050;
}

.lglogin {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  margin-top: 15px;
  margin-bottom: 32px;
}
.lgwellconote {
  font-family: "Montserrat";
  /* font-style: normal; */
  font-weight: 600;
  font-size: 32px;
  white-space: nowrap !important;

  /* margin-bottom: px */
}
.lgcardstyling {
  padding: 30px 68px;
  /* margin-top: 142px; */
  margin-top: 50px;
  /* border-radius: 10px; */
  color: #172659;
}

.lgdimondlogo {
  max-height: 191px;
  max-width: 266px;
}
/* .image-wrap {
  position: fixed;
} */

.lgcardpadding {
  /* justify-items: center; */
  padding-top: 40px;
  /* padding-left: px; */
  padding-right: 30px;
  /* width: 80%; */
}

.lgInput {
  height: 50px;
  /* margin-top: 5px; */
  width: 100%;
  margin-bottom: 18px;
  border-radius: 4px;
  /* background: rgba(202, 202, 202, 0.1); */
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.lgbtn {
  background-color: #000000;
  border: none;
  width: 100%;
  height: 48px;
  border-radius: 4px;
}

.lganchor {
  display: flex;
  color: #000000;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;

  /* margin-left: 25px; */
  /* padding: 30px; */
}

.lg-card {
  justify-content: center;
  align-items: center;
}

.side-container {
  align-items: center;
  justify-content: center;
}

.login-frame {
  margin-top: 35px;
}
.eye {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-left: 15px;
  background: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  color: 333333;
  height: 46px;
  width: 45px;
  right: 41px;
  top: 170px;
}

.eye1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-left: 15px;
  background: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  color: 333333;
  height: 46px;
  width: 45px;
  right: 41px;
  top: 170px;
}

.noneIcon {
  display: none;
}

.invalid-icon1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  top: 120px;
  border: none;
  border-radius: 5px;
  z-index: 100;
  background: rgba(239, 64, 80, 1);
  right: 69px;
}
.invalid-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  top: 186px;
  border: none;
  border-radius: 5px;
  z-index: 100;
  background: rgba(239, 64, 80, 1);
  right: 69px;
}
.topLogo {
  display: none;
}

.bottomLogo {
  display: none;
}
@media only screen and (max-width: 900px) {
  .loginImg {
    display: none;
  }
  .bottomLogo {
    display: block;
    width: 100%;
    background-color: #000000;
    padding-top: 100px;
  }

  .topLogo {
    display: block;
    width: 100%;
  }
  .loginCard {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .loginImg {
    display: none;
  }

  .mob-img {
    background-image: url("../../../images/icons/back-img.svg");
    background-position: bottom;
    height: 150px;
    width: 100%;
    background-repeat: no-repeat;
    background-color: #172659;
  }
  .login-frame {
    margin-top: 0;
  }
  .image-wrap {
    display: none;
  }
  .lgwellconote {
    font-size: 25px;
    font-family: "Montserrat";
    text-align: center;
    /* margin-left: 10px !important; */
  }
  .lgcardpadding {
    /* margin-right: 10px; */
    margin-left: 10px;
    margin-top: 1px;
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  .invalid-icon1 {
    right: 30px;
  }
  .invalid-icon {
    right: 30px;
  }
  .lgcardstyling {
    padding: 30px;
  }
  /* .eye {
    position: absolute;
    top: 187px;
    height: 30px;
    right: 70px;
  } */
}

@media only screen and (min-width: 1500px) {
  .fintech-img {
    min-width: 50vw;
    position: fixed;
  }
}
