.accordion {
  flex-direction: column;
  align-items: center;
  display: block;
}
.accordion__item {
  width: 100%;
  /* max-height: 25px; */
  /* min-height: 100%; */
  /* padding: 17px 10px; */
  /* padding-bottom: 10%; */
  border-bottom: 1px solid #c9c9c9;
  overflow: hidden;
  cursor: pointer;
}
.accordion__header {
  padding-top: 10px;
  align-items: center;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  border: 1px solid #f6f6f6 !important;
  background-color: #f6f6f6 !important;
}
.accordion__header h4 {
  transition: 0.2s ease-in-out;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.accordion__header i {
  transition: 0.2s ease-in-out;
  transform-origin: center;
  margin-bottom: 10px;
}
.accordion__header:hover h4 {
  color: black !important;
}
.accordion__header:hover i {
  color: black;
}
.accordion__content {
  background-color: white;
  margin: 5px;
  display: block;
}
@media (max-width: 600px) {
  .accordion__item {
    width: 80%;
  }
}
