.paymentCardCol {
  padding-left: 13px;
}

.bookingCardCol {
  padding-right: 13px;
}

@media only screen and (max-width: 600px) {
  .paymentCardCol {
    padding-left: 0px;
    padding-top: 20px;
  }

  .bookingCardCol {
    padding-right: 0px;
  }
}
