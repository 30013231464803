.add-memeber-card .css-13cymwt-control {
  min-height: 48px;
}

.add-memeber-card .css-t3ipsp-control {
  min-height: 48px;
}

.add-memeber-card .custom-select {
  height: auto;
}
