.add-reason {
  margin-top: 16px;
  float: right;
  width: 174px;
  height: 48px;
  background: #000000;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
  margin-right: 40px;
}
.reasons-card {
  margin-top: 17px;
  border-radius: 4px;
  padding-left: 31px;
  padding-top: 22px;
  margin-right: 40px;
  margin-left: 30px;
  padding-bottom: 40px;
}
.reasons-card h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  color: #000000;
}

.reason-text {
  padding: 12px;
  /* min-height: 46px !important; */
  background: rgba(202, 202, 202, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.reason-text span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.edit-btn {
  display: flex;
  align-items: center;
  padding-left: 40px;
}
.reasons-row {
  margin-top: 24px;
}
.edit-btn a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  margin-right: 12px;
}

@media only screen and (max-width: 600px) {
  .reasons-card h4 {
    font-size: 18px;
  }
  .edit-btn {
    /* display: flex;
    margin-left: 90px;
    margin-top: 15px; */
    float: left;
  }

  .edit-btn a {
    font-size: 15px;
  }
  .reason-text span {
    font-size: 13px;
  }

  .add-reason {
    margin-right: 20px;
  }
  .reasons-card {
    margin-right: 20px;
    padding: 10px;
  }
}
