.crossBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.otpInput {
  height: 100%;
  width: 100%;
}

.otpModelCard {
  padding-top: 60px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 25px;
}

.otpModelHeader {
  display: flex;
  justify-content: center;
}

.otpModelHeader h3 {
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  font-weight: 500;
  font-size: 25px;
}
.otpModelHeader p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #999999;
}
