.sidebarheadings {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-left: 20px;
}

.sideMenuLinks {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 15px;
}

.sidebarlogout {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* margin-left: 20px; */
  color: #ef4050;
}

.verticalNavIcon {
  margin-right: 25px;
  width: 20px;
}

.finTechIcon {
  margin-top: 33px;
}
.sideBarImg {
  display: flex;
  justify-content: center;
}
.side-Tab {
  margin-top: 40px;
}

.side-nav-icon {
  display: none;
}

.pro-sidebar {
  position: fixed;
  display: inline-block;
  vertical-align: top;
  width: 18%;
}

.activesidebar {
  border-left: 3px white solid;
  background: linear-gradient(
    90deg,
    rgba(1, 41, 250, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  border-radius: 0px 5px 5px 0px;
}
.inactivesidebar {
  color: azure;
}

.vlHR {
  width: 110%;
}
.nexudusItem {
  margin-top: 180px;
}

.sideNav-bar {
  z-index: 999;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .sideNavShow {
    width: 100%;
  }
  .side-nav-icon {
    display: block;
    color: white;
  }
}
@media only screen and (max-width: 1200px) {
  .finTechIcon span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1366px) {
  .nexudusItem {
    margin-top: 100px;
  }
}
