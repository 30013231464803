.passwordModelIcon {
  display: flex;
  justify-content: center;
}

.passwordModelIcon h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  padding-top: 40px;
}

.passwordModelIcon p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

.tmpPasswordCard {
  padding-top: 20px;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}
