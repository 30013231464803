@media only screen and (max-width: 600px) {
  .dm-btn1 {
    white-space: nowrap !important;
    font-size: 10px;
  }
  .dm-btn2 {
    white-space: nowrap !important;
    font-size: 10px;
  }
}

.checkbox {
  text-align: right;
}

.rejectInput {
  height: 48px;
  width: 100%;
  /* margin-top: 5px; */
  margin-bottom: 18px;
  background: rgba(202, 202, 202, 0.1);
  border: none;
}

.checkLabel {
  display: flex;
  flex: auto;
  vertical-align: middle;
  align-items: center;
  justify-content: left;
  text-align: center;
  cursor: pointer;
  background-color: none;
  /* background-color: #ebebeb; */
  color: #000000;
  padding: 5px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #cacaca;
  border-radius: 4px;
}

/* label:last-of-type {
  margin-right: 0;
} */

input[type="radio"]:checked + label {
  border: 1px solid #0129fa;
  background-color: rgba(1, 41, 250, 0.1);
  color: #000000;
}

input[type="radio"]:disabled + label {
  background-color: #ebebeb;
  color: #000000;
  border: none;
}

input[type="radio"]:hover :disabled + label {
  background-color: #ebebeb;
  color: #000000;
  border: none;
}

/* input[type="radio"]:hover:not(:checked) + label {
  background-color: #35d7a1;
  color: white;
} */
.InputGroup {
  display: flex;
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 5px;
  /* background-color: #cacaca; */
}
input[type="radio"] {
  visibility: hidden; /* 1 */
  height: 0; /* 2 */
  width: 0; /* 2 */
  /* background-color: #999999; */
}

.close-btn {
  width: 5px;
  height: 5px;
}
.dm-textarea {
  resize: none;
  height: 80px;
}

.d-body {
  font-family: "Montserrat";
  font-style: normal;
}
.dm-btn1 {
  border-radius: 4px;
  background-color: white;
  border: 1px #cacaca solid;
  color: #000000;
  width: 125px;
  height: 45px;
  font-weight: 600;
}
.dm-btn2 {
  border-radius: 4px;
  background-color: #000000;
  border: none;
  width: 206px;
  font-weight: 500;
  font-size: 16px;
  height: 45px;
}

.cancelModelHeader {
  color: 000000;
  font-size: 25px;
  font-weight: 500;
}

.approvedDenyBtn1 {
  width: 125px;
  height: 45px;
  border-radius: 4px;
  color: #ffffff;
  border: none;
  background: #999999;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.approvedDenyBtn2 {
  width: 206px;
  height: 45px;
  border-radius: 4px;
  border: none;
  background: #000000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
