.setting-card {
  width: 100%;
  height: 110px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 50px 100%;
  background-position: left;
  background-position-x: 20px;
}

.tourSetting {
  background-image: url("../../../images/icons/toursSettings.svg");
}

.rejectionEmail {
  background-image: url("../../../images/icons/rejectionEmail.svg");
}

.changePassword {
  background-image: url("../../../images/icons/changePassword.svg");
}

.userRoles {
  background-image: url("../../../images/icons/userRoles.svg");
}
.notificationSetting {
  background-image: url("../../../images/icons/notificationSetting.svg");
}
.bookingPayment {
  background-image: url("../../../images/icons/paymentBooking.svg");
}

.card-setting {
  margin-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.settings-page {
  margin-top: 45px;
  padding-left: 25px;
  padding-right: 25px;
}

.settings-page_row_2 {
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
}
.settings-page h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  /* float: ; */
  position: absolute;
  left: 85px;
  top: 40px;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.settings-page_row_2 h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  /* float: ; */
  position: absolute;
  left: 85px;
  font-size: 16px;
  /* line-height: 22px; */
  color: #000000;
}

@media only screen and (max-width: 600px) {
  .setting-card {
    margin-top: 20px;
  }
  .settings-page {
    margin-top: 0;
    /* margin-left: 20px; */
    /* margin-right: 40px; */
  }

  .settings-page_row_2 {
    margin-top: 0;
    /* margin-left: 20px; */
    /* margin-right: 40px; */
  }
  .card-setting {
    /* margin-left: 40px; */
    padding-right: 0px;
    padding-left: 0px;
  }
}
