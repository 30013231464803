.nav-request-bar {
  border-bottom: 5px solid #cccccc;
  border: transparent;
}

.membershipCardHeading {
  display: flex;
  justify-content: space-between;
}

.memberCardData h6 {
  font-family: "Montserrat";
  font-style: normal;
  line-height: 28px;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
}
.membershipCardHeading h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
.memberCardData h5 {
  font-family: "Montserrat";
  font-style: normal;
  line-height: 28px;
  font-weight: 500;
  font-size: 13px;
  color: #828282;
}

.membershipPage {
  padding-left: 15px;
  padding-right: 20px;
}
.membershipDetailBtn {
  display: flex;
}

.teamCardHeading Table thead tr th {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.teamCardHeading Table thead tr th {
  border: none;
}
.teamCardHeading Table {
  border-collapse: separate;
  border-spacing: 0 10px;
  text-align: start;
  white-space: nowrap;
}

.teamCardHeading Table td:first-child,
.teamCardHeading Table th:first-child {
  border-radius: 4px 0 0 4px;
}

.teamCardHeading Table td:last-child,
.teamCardHeading Table th:last-child {
  border-radius: 0 4px 4px 0;
}

.teamCardHeading Table tbody tr td {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.teamCardHeading Table tbody tr td {
  border: none;
}
.teamCardHeading Table tbody tr {
  background-color: #f2f2f2;
}
.teamStatus span {
  font-size: 10px;
  border-radius: 2px;
  color: white;
  padding: 2px 5px 2px 5px;
  margin: 2px;
}
.payingMember {
  background-color: #0129fa;
}
.adminstrator {
  background-color: #f05f23;
}
.memberDescription {
  background: rgba(202, 202, 202, 0.1);
  padding: 5px;
}

.memberDescription p {
  font-family: "Montserrat";
  font-style: normal;
  line-height: 28px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  text-align: justify;
}

.membershipCard {
  height: 100%;
  padding: 29px 27px 27px 20px;
  border: none;
}

@media only screen and (max-width: 600px) {
  .memberCardData h5 {
    font-size: 12px;
  }

  .membershipPage {
    padding-left: 5px;
    padding-right: 5px;
  }

  .memberCardData h6 {
    font-size: 12px;
  }
}
