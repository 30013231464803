.SuccessSpan {
  color: #0129fa;
}

.FailedSpan {
  color: #ef4050;
}
.logsCardView {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.logsDetailPage {
  padding-left: 30px;
  padding-right: 30px;
}
.logsCardView h1 {
  font-family: "Montserrat";
  font-style: normal;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;
  color: #000000;
}

.logsCardView p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.4);
}

.logsCardView h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.4);
}

.logsCardView h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.logsCardHeader {
  display: flex;
}

.failedContainer {
  background: rgba(239, 64, 80, 0.2);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.successContainer {
  background: rgba(53, 215, 161, 0.2);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.successContainer span {
  font-family: "Montserrat";
  font-style: normal;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 12px;
  color: #0129fa;
}
.failedContainer span {
  font-family: "Montserrat";
  font-style: normal;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 12px;
  color: #ef4050;
}
