.tourDetialCOl {
  padding-right: 30px;
}

.suspendBtnContainer {
  display: flex;
  justify-content: end;
}

@media only screen and (max-width: 600px) {
}
