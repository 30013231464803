.verifyBtn {
  width: 111px;
  height: 33px;
  font-size: 14px;
  color: white;
  background: #35d7a1;
  border: none;
  border-radius: 5px;
  --bs-btn-hover-bg: rgba(23, 38, 89, 0.9);
}
