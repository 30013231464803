$sidebar-bg-color: #000000 !default;
$sidebar-color: #cccccc !default;
$sidebar-width: 20%;
$min-width: 0px;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #000000 !default;
$icon-bg-color: #172659 !default;
$icon-size: 35px !default;
$submenu-indent: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";
