.accessLimitedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.accessLimitedContainer h1 {
  color: #000;
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 600;
}
.accessLimitedContainer p {
  color: #000;
  font-size: 18px;
  font-family: "Montserrat";
  line-height: 165.9%;
  letter-spacing: -0.18px;
}
