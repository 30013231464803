.bookingSettingPage {
  padding-left: 30px;
  padding-right: 30px;
}

.bookingSettingCardViwe {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  /* margin: 30px; */
}

.bookingSettingCardViwe h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
.bookingSettingCardViwe p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #808080;
}

.confirmBookingContainer {
  border-radius: 4px;
  background: #ededed;
}

.confirmBookingContainer h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #808080;
}

.dayToggleCard {
  padding-right: 15px;
}

.dayPassCard {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  border: none;
}

.dayPassCard h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #000000;
}

.verticalLine {
  position: absolute;
  top: 10px;
  right: 480px;
  height: 80%;
  width: 2px;
  border: 1px solid #000000;
  opacity: 0.1;
}

.verticalCol {
  width: 2px;
}

.dayPassDiscountDD {
  border: none;
  height: 58px;
  border-radius: 4px;
  width: 100%;
  background-color: #faf9f9;
}

.dayPassDiscountDDCol {
  padding-left: 15px;
  padding-right: 15px;
}

.discountInput {
  height: 32px;
}

@media only screen and (max-width: 767px) {
  .meetingRoomCol {
    padding-top: 30px;
  }
  .membershipHolderCol {
    padding-top: 20px;
  }
  .dayToggleCard {
    padding-right: 0px;
  }

  .dayPassDiscountDDCol {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 994px) {
  .meetingRoomCol {
    padding-top: 30px;
  }
}
