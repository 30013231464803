.memberDetailCard {
  border: none;
  border-radius: 4px;
}
.memberDetailCard h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}
.memberDetailCard h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0129fa;
}
.memberDetailCard h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.memberDetailCard h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.memberDetailCard h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}

.squareLogo {
  background: #35d7a1;
  border-radius: 2px;
  width: 12px;
  height: 11px;
  border: none;
}
.SelectDeskMemberField {
  background: #fafafa;
  border: 1px solid #636363;
  border-radius: 4px;
  width: 100%;
  height: 39px;
}
