.add-user {
  float: right;
  width: 150px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 10px;
  color: #000000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
}

.listEditBtnContainer {
  display: flex;
}

.listEditBtnContainer a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.userImgContainer {
  padding-left: 30px;
}

.rolesBtn {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 20px;
  --bs-btn-hover-border-color: none;
  --bs-btn-hover-bg: none;
  --bs-btn-hover-color: none;
  --bs-btn-focus-shadow-rgb: none;
  height: 48px;
}

input[type='checkbox'] {
  accent-color: #0129FA;
}
