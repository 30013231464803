.objectionPending {
  border: none;
  background: rgba(240, 95, 35, 0.1);
  border-radius: 4px;
  width: 64px;
  height: 16px;
  color: #f05f23;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.objectionRejected {
  border: none;
  background: rgba(240, 95, 35, 0.1);
  border-radius: 4px;
  width: 64px;
  height: 16px;
  color: #ef4050;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.objectionInvoiceBtn {
  height: 48px;
  margin-right: 20px;
  background: #f05f23;
  border: none;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-hover-bg: #f05f23;
  --bs-btn-hover-color: white;
  --bs-btn-hover-border-color: none;
}

.teamInvoiceHeaderContainer .invoiceHeaderDetail {
  text-align: right;
  font-family: "Montserrat";
  font-style: normal;
}

.teamInvoiceHeaderContainer .invoiceHeaderDetail h6 {
  font-size: 18px;
  color: #000000;
}
.teamInvoiceHeaderContainer .invoiceHeaderDetail h4 {
  font-weight: 700;
  font-size: 30px;
  color: #000000;
}
.teamInvoiceHeaderContainer .invoiceHeaderDetail p {
  font-weight: 500;
  font-size: 14px;
  color: #626262;
}

.teamInvoiceHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.invoiceBilling h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
.invoiceBilling h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #000000;
}

.billingCol {
  padding-left: 20px;
}

.fullviewBtn {
  border: 1px solid #000000;
  position: absolute;

  transform: translate(-150%, 500%);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  color: #000000;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px;
}

.invoiceInfoCol {
  padding-right: 20px;
}

@media only screen and (max-width: 600px) {
  .teamInvoiceHeaderContainer .invoiceHeaderDetail h6 {
    font-size: 12px;
    color: #000000;
  }
  .teamInvoiceHeaderContainer .invoiceHeaderDetail h4 {
    font-weight: 700;
    font-size: 14px;
    color: #000000;
  }
  .teamInvoiceHeaderContainer .invoiceHeaderDetail p {
    font-weight: 500;
    font-size: 10px;
    color: #626262;
  }

  .invoiceBilling {
    margin-top: 15px;
  }
}
