.cardHeading {
  display: flex;
  justify-content: space-between;
}

/* Tours CSS */
.sr-only {
  display: none;
}

.card {
  --bs-card-border-color: rgb(0 0 0 / 7%);
}

.data-indication h1 {
  font-size: 20px;
  font-family: "Montserrat";
  color: #000000;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  margin-left: 7px;
  margin-right: 7px;
  background-color: #000000;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.btn-circle:focus {
  outline: none;
  box-shadow: none;
  background-color: #000000;
}

.btn-circle2 {
  width: 41px;
  height: 41px;
  padding: 6px 0px;
  border-radius: 22.5px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  color: #ef4050;
  background-color: rgba(239, 64, 80, 0.2);
  border: none;
  --bs-btn-hover-bg: rgba(239, 64, 80, 0.8);
  --bs-btn-focus-shadow-rgb: none;
}

.btn-circle1 {
  width: 41px;
  height: 41px;
  padding: 6px 0px;
  border-radius: 22.5px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  color: #0129fa;
  background-color: rgba(1, 41, 250, 0.2);
  border: none;
  --bs-btn-hover-bg: rgba(1, 41, 250, 0.8);
  --bs-btn-focus-shadow-rgb: none;
}

.paginationitmes1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;
  margin-top: 5px;
}
.paginationitmes {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

.row {
  --bs-gutter-x: 0rem;
  --bs-gutter-y: 0;
}

.tableHeadercss {
  font-family: "Montserrat";
  font-style: normal;
  /* font-weight: 500; */
  font-size: 14px;
  /* margin-bottom: 20px; */
  border-bottom: 4px solid #f7f7f8 !important;
  /* position: relative;   */
  color: #000000;
  /* border: #414141; */
  /* border: #f7f7f8; */
  border-width: 100%;
  border-radius: 5px;
  /* border-bottom-color: 5px solid black; */
  /* border-collapse: separate; */
  /* border-spacing: 1px ; 
        border-width: 10px; */
}
.searchbcss {
  display: flex;
  border-radius: 4px;
  border: none;
  height: 52px;
  width: 100%;
  background-image: url("../../../../images/icons/Search.svg");
  background-repeat: no-repeat;
  /* background-position: 150px; */
  background-position: right 10px center;
  background-size: 25px 25px;
}

.detailPageHeader {
  display: flex;
  justify-content: space-between;
}

.table-wrap {
  padding: 5px;
}
.tourCalenderViewCol {
  /* padding-right: 20px; */
  align-items: center;
  justify-content: end;
  display: flex;
}
.tourCalenderViewCol span {
  float: right;
}
.tourCalenderViewCol span img {
  padding-left: 10px;
}

.tourCalenderViewCol span a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  color: #000000;
}
.data-wrap {
  border-radius: 10px;
  margin: 5px;
  /* margin-top: 5px; */
  /* margin-bottom: 10px; */
}
.pagination-btn {
  white-space: nowrap;
}
.input-wrap {
  border: none;
  margin: 35px;
  border-radius: 10px;
  /* overflow: scroll; */
}
.input-wrap2 {
  border: none;
  border-radius: 4px;
}

.nav-width {
  width: 100%;
  margin-left: -16px;
  background-color: #f7f7f8;
}
.nav-link {
  border: transparent;
  height: 48px;
}

.d-none {
  font-family: "Montserrat";
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: block !important;
  /* line-height: 20px; */
}
.nav-btn {
  background-color: white !important;
  margin: 1px;
  /* border: none !important; */
  /* border: #ffffff; */
}

.position-relative {
  margin-left: 60px;
}

.pageBtn {
  background-color: #ffffff;
  color: black;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border: none;
}

.bottom-btn {
  padding: 20px;
}

.page-btn {
  background: none;
  /* color: #cccccc; */
  color: #000000;
  border: none;
}
.nav-link {
  border-bottom: 5px solid #cccccc;
}

.nav-link.active {
  border-bottom: 5px solid #000000;
}

.nav-item {
  display: flex;
  border-radius: 4px;
}
.nav-btn .nav-item span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.btn-circle-approved {
  font-family: "Montserrat";
  font-weight: 400;
  width: 115px;
  height: 41px;
  padding: 6px 0px;
  border: none;
  border-radius: 22.5px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #0129fa;
  background-color: rgba(1, 41, 250, 0.2);
  --bs-btn-hover-bg: rgba(1, 41, 250, 0.1) !important;
  --bs-btn-focus-shadow-rgb: none;
}

.btn-circle-denied {
  font-family: "Montserrat";
  font-weight: 400;
  width: 115px;
  height: 41px;
  padding: 6px 0px;
  border: none;
  border-radius: 22.5px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #ef4050;
  line-height: 17px;
  background-color: rgba(239, 64, 80, 0.1);
  --bs-btn-hover-bg: rgba(239, 64, 80, 0.1);
  --bs-btn-focus-shadow-rgb: none;
}

.btn-circle-object {
  font-family: "Montserrat";
  font-weight: 400;
  width: 115px;
  height: 41px;
  padding: 6px 0px;
  border: none;
  border-radius: 22.5px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #dc6837;
  line-height: 17px;
  background: rgba(220, 104, 55, 0.1);
  --bs-btn-hover-bg: rgba(239, 64, 80, 0.1);
  --bs-btn-focus-shadow-rgb: none;
}

.btn-circle-cancel {
  font-family: "Montserrat";
  font-weight: 400;
  width: 115px;
  height: 41px;
  padding: 6px 0px;
  border: none;
  border-radius: 22.5px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #e72828;
  line-height: 17px;
  background: rgba(255, 0, 0, 0.1);
  --bs-btn-hover-bg: rgba(239, 64, 80, 0.1);
  --bs-btn-focus-shadow-rgb: none;
}
.searchBarCol {
  display: flex;
  justify-content: center;
  /* padding-right: 20px; */
}
.search-row {
  float: right;
}
.search-bar {
  border-radius: 4px;
  border: none;
  height: 52px;
  width: 100%;
  float: right;
  /* margin-right: 5px; */
}

.tab-row {
  display: flex;
  justify-content: space-between;
}

.calenderView {
  cursor: pointer;
  text-decoration: underline;
  color: #000000;
  font-size: 16px;
}

/* Tours Datails CSS */
.datails-btn1 {
  height: 48px;
  border: none;
  margin-right: 20px;
  background: #ef4050;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  --bs-btn-focus-shadow-rgb: none;
}

.datails-btn1:focus {
  outline: none;
  box-shadow: none;
  background-color: #ef4050;
}

.datails-btn2:focus {
  outline: none;
  box-shadow: none;
  background-color: #000000;
}

.datails-btn2 {
  height: 48px;
  border: none;
  background: #000000;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.tourDetailPage {
  padding-left: 30px;
  padding-right: 30px;
}
.bottombtn {
  /* margin-top: 24px; */
  float: right;
  /* display: flex; */
}

.toursCardView {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  /* margin: 30px; */
}
.info-heading {
  font-family: "Montserrat";
  /* white-space: nowrap; */
}

.toursCardView h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  color: #000000;
  font-size: 16px;
}

.toursCardView h5 {
  font-family: "Montserrat";
  font-style: normal;
  line-height: 21px;
  font-weight: 500;
  font-size: 16px;
  color: #828282;
}

.toursCardView h6 {
  font-family: "Montserrat";
  font-style: normal;
  line-height: 21px;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.info-container {
  margin-top: 32px;
}

.col-info {
  text-align: right;
  color: black !important;
}
.spinner {
  top: 50;
  margin-left: 80px;
}

.searchCustomBar {
  height: 38px;
  border-radius: 20px;
  width: 100%;
}

.detailApprovedBtn {
  float: right;
  background: rgba(1, 41, 250, 0.1);
  border: 1px solid #0129fa;
  border-radius: 4px;
  width: 110px;
  height: 34px;
  color: #0129fa;
  font-family: "Montserrat";
  cursor: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.detailPendingBtn {
  float: right;
  background: rgba(220, 104, 55, 0.1);
  border: 1px solid #dc6837;
  border-radius: 4px;
  width: 110px;
  height: 34px;
  color: #dc6837;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  cursor: none;
}

.detailDeniedBtn {
  float: right;
  background: rgba(240, 95, 35, 0.1);
  border: 1px solid #ef4050;
  border-radius: 4px;
  width: 110px;
  height: 34px;
  color: #ef4050;
  font-family: "Montserrat";
  font-style: normal;
  cursor: auto;
  font-weight: 500;
  font-size: 12px;
}

.table-responsive table tbody tr:hover {
  cursor: pointer;
}

.detailApprovedCancelBtn {
  height: 45px;
  border: none;
  background: #ef4050;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.view-table {
  overflow: auto;
  table-layout: auto !important;
  white-space: nowrap;
}

.data-wrap .csvButton {
  border: 1px solid #626262;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  background-color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #626262;
}
/* .data-wrap .csvButton .csvExport {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #626262;
  text-decoration: none;
} */

@media only screen and (max-width: 1000px) {
  .tourCalenderViewCol {
    justify-content: center;
    padding-bottom: 10px;
  }

  .nav-width {
    width: auto;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .btn-circle {
    width: 25px;
    height: 25px;
    font-size: 10px;
  }
  .pageBtn {
    height: 35px;
    font-size: 12px;
  }

  .search-bar {
    width: 100%;
  }
  .searchBarCol {
    padding-right: 10px;
  }

  .search-row {
    float: none;
  }
  .mob-anchor {
    margin-left: -30px;
  }

  .datails-btn2 {
    margin-right: 0px;
  }
  .detailApprovedCancelBtn {
    margin-right: -40px;
  }
  .bottombtn {
    display: flex;
  }
  .toursCardView h6 {
    font-size: 13px;
  }
  .toursCardView h5 {
    font-size: 13px;
  }
  .toursCardView h3 {
    font-size: 15px;
  }

  .btn-circle {
    display: none;
  }

  .page-btn {
    border-radius: 4px;
    /* padding: 20px; */
    height: 30px;
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #828282;
    /* height: 50%; */
    display: flex;
    justify-content: center;
  }

  .data-wrap .csvButton {
    display: none;
  }
}
