.tourEventModel {
  padding-left: 20px;
  padding-right: 20px;
}

.tourEventModel h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #000000;
}

.tourEventModel .tour-user-details .info-heading h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4);
}

.tourEventModel .tour-user-details .tour-card-info h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.tour-card-info {
  display: flex;
  justify-content: end;
}
