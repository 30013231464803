.password-card {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  /* margin: 30px; */
}
.password-card h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000000;
}
.invalidSpan {
  color: #ef4050;
}
.password-card h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.invalid {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background: #ffffff;
  border: #ef4050 solid 2px;
}

.password-card li {
  width: 100%;
  list-style-type: "◆";
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}

.password-card li span {
  margin-left: 21px;
}
.input-col-password {
  padding-right: 30px;
}
.hideError {
  display: none;
}
.displayError {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ef4050;
}

.password-card h6 {
  padding-top: 23px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.password-margin {
  padding-left: 50px;
}
.password-margin2 {
  padding-left: 100px;
}
.password-btn {
  margin-top: 41px;
  width: 100%;
  /* width: 365px; */
  height: 60px;
  background: #35d7a1;
  border-radius: 10px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}
.password-field {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #cacaca;
}

@media only screen and (max-width: 600px) {
  /* .password-card li {
    font-size: 13px;
  }
  .password-field {
    width: 280px;
  }
  .password-margin {
    padding-left: 0;
  }
  .password-margin2 {
    padding-left: 0px;
  }
  .password-btn {
    width: 280px;
  } */
}
