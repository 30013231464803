.dashboardCard {
  border-radius: 16px;
  border: none;
}

.dashboardCard .statsCard {
  background: rgba(1, 41, 250, 0.2);
  border-radius: 16px;
  border: none;
}

.dashboardCard .statsCard h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #252424;
}
.dashboardCard h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #252424;
}
.dashboardCard .statsCard span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  color: #696969;
}

.dashboardCard .statsCardCol {
  padding-right: 10px;
}

.dashboardCheckin {
  margin-left: 20px;
}

@media only screen and (max-width: 767px) {
  .dashboardCard .statsCardCol {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 1199px) {
  .dashboardCheckin {
    margin-left: 0px;
  }
}
