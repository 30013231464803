/* dragDrop= dd  */
.dd_name_Entity {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: transparent;
}

.dd_name_fonts {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}
.dd_Card_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin-left: 3px;
}

.dd_members_h {
  float: right;
  margin-right: 10px;
  display: flex;
}
.dd_mex_members {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 21px;
  /* identical to box height, or 210% */
  display: flex;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.6);
}
.dd_max_no {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  /* identical to box height, or 150% */

  color: #000000;
}
