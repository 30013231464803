.lgCard h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #000000;
}

.lgCard h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.lgCard span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
}

.forgotPasswordEye {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 15px; */
  /* padding-left: 15px; */
  background: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  color: 333333;
  height: 48px;
  width: 45px;
  right: 25px;
  bottom: 107px;
}

.forgotPasswordEye1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 15px; */
  /* padding-left: 15px; */
  background: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  color: 333333;
  height: 48px;
  width: 45px;
  right: 25px;
  bottom: 202px;
}

.lgCard li .invalidSpan {
  color: #ef4050;
}

.lgCard li {
  width: 100%;
  list-style-type: "◆";
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}

.lgCard li span {
  margin-left: 21px;
}

.forgotpPageCard {
  margin-top: 165px;
}

@media only screen and (max-width: 1366px) {
  .lgMain .forgotpPageCard {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 900px) {
  .forgotpPageCard {
    margin-left: 20px;
    margin-right: 20px;
  }
}
