.teamCardHeading .paid {
  color: #00763c;
}

.teamCardHeading .pending {
  color: #dc6837;
}

.teamCardHeading .objection {
  color: #f05f23;
}

.teamCardHeading .denied {
  color: #ef4050;
}

.teamCardHeading .invoceAchnerDetail {
  color: #000000;
  text-decoration: underline;
}

.cardHeading .pendingStatusShow {
  color: #f05f23;
  font-weight: 600;
}
.cardHeading .paidStatusShow {
  color: #00763c;
  font-weight: 600;
}
.cardHeading .objectionStatusShow {
  color: #f05f23;
  font-weight: 600;
}
.cardHeading .deniedStatusShow {
  color: #ef4050;
  font-weight: 600;
}

.addNewMemberBtn {
  background-color: rgba(53, 215, 161, 0.1);
  border: none;
  color: #000000;
  font-family: "Montserrat";
  font-size: 12px;
  border-radius: 4px;
}

.documentsImg {
  border-radius: 8px;
  width: 35%;
}
