.imgContainer {
  float: right;
}

.objectionTable Table thead tr th {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  color: #000000;
}

.objectionTable Table tbody tr td {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 35px;
  color: #626262;
}

.objectionTable Table tbody tr {
  height: 50px;
}

.objectionBtn {
  height: 48px;
  margin-right: 20px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #f05f23;
  color: #f05f23;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-hover-bg: none;
  --bs-btn-hover-color: none;
  --bs-btn-hover-border-color: #000000;
}
