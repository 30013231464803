/* .calenderModel {
  padding-top: 10px;
} */

.fc .fc-toolbar-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #080808;
}
.fc-theme-standard td {
  background: #fafafa;
  border: 1px solid #e6e6e6 !important;
  /* border: none !important; */
}

/* .fc-theme-standard td, */
.fc-theme-standard th {
  border: none;
}

.fc .fc-scrollgrid-liquid {
  height: 100%;
  border: none;
}
.fc-toolbar-chunk {
  justify-content: center;
  align-items: center;
  display: flex;
}
.fc-col-header-cell-cushion {
  font-family: "Montserrat";
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}
.fc {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-radius: 5px;
}

.fc,
.fc *,
.fc *:before,
.fc *:after {
  box-sizing: border-box;
  border-radius: 5px;
}

/* .fc tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-radius: 5px;
} */

/* .fc .fc-scrollgrid-section {
  border: none !important;
  border-radius: 5px !important;
} */
.fc .fc-scroller-harness {
  border: none !important;
  /* border-radius: 10px; */
}

.fc .fc-daygrid-day-top {
  padding: 9px 16px;
  flex-direction: column;
}

.fc-daygrid-day-number {
  font-family: "Montserrat";
  font-style: normal;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  color: #080808;
}

.fc .fc-today-button {
  font-family: "Montserrat";
  text-transform: capitalize;
  font-style: normal;
  color: black !important;
  font-weight: 600;
  font-size: 18px;
  height: 45px;
  width: 125px;
  cursor: pointer;
  border: 1px #cacaca solid !important;
  border-radius: 4px;
  background: white !important;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  height: 45px;
  border: none;
  border-radius: 5px;
  width: 62px;
  padding: 5px;
  background-color: white;
  color: #000000;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  height: 45px;
  border: none;
  border-radius: 5px;
  width: 62px;
  padding: 5px;
  background-color: white;
  color: #000000;
}

.fc-daygrid-event {
  padding-left: 5px;
  /* white-space: unset; */
  cursor: pointer;
  background: #000000;
  border: none;
  /* padding-left: 16px; */
}

/* .fc-daygrid-block-event .fc-event-title {
  display: list-item;
  list-style-type: "• ";
  color: #999999;
  list-style-position: inside;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
} */

.fc .fc-daygrid-day-frame {
  background-color: #fafafa;
}

/* .fc .fc-daygrid-day-top {
  padding-bottom: 35px;
} */

@media only screen and (max-width: 600px) {
  .fc .fc-toolbar-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #080808;
  }

  .fc .fc-today-button {
    height: 45px;
    width: 100px;
  }
}
