.tour-slot-model {
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}
.slot-model {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slot-model h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}
.tour-slot-col h3 {
  margin-top: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
/* .tour-slot-col {
  padding-right: 50px;
} */

.tourslotDuration {
  background-color: #fafafa;
  border: none;
  border-radius: 4px;
  height: 48px;
  width: 100%;
}
.selectdays-col h3 {
  margin-top: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.cancelPrimaryBtn {
  height: 48px;
  border: none;
  margin-right: 20px;
  background: #ffffff;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.tours-day-row {
  margin-top: 10px;
}

.input-tours-days {
  width: 25px;
  height: 25px;
  border-radius: 2px !important;
  border: 2px solid #000000;
}

.input-tours {
  width: 25px;
  height: 25px;
  border-radius: 2px !important;
  border: 2px solid rgba(0,0,0,0.10);
}

.tour-slot-btn-row {
  padding-top: 30px;
}

.addSlotModelBtn {
  display: flex;
  justify-content: center;
}

.tour-slot-btns {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
