.accessControlPage h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000000;
}
.sync-btn {
  height: 48px;
  border: none;
  margin-right: 20px;
  background: none;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #999999;
  --bs-btn-focus-shadow-rgb: none;
  color: #000000;
}
.deparymentsAccessCard {
  margin-right: 20px;
  border: none;
}

.departmentCardsContainer {
  height: 62vh;

  white-space: nowrap;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: auto; /* Enable vertical scrolling */
}

.checkinCardsContainer {
  height: 69vh;

  overflow-y: auto; /* Enable vertical scrolling */
}

.departmentCardsContainer::-webkit-scrollbar {
  width: 0; /* Set the width of the scrollbar */
}
.checkinCardsContainer::-webkit-scrollbar {
  width: 0; /* Set the width of the scrollbar */
}

.deparymentsAccessCard h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.deparymentsAccessCard p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #828282;
}

.departmentCards {
  background: #ffffff;
  /* Team */

  border: 1px solid rgba(202, 202, 202, 0.51);
  border-radius: 8px;

  margin-top: 10px;
}

.deparymentsAccessCard .departmentCards h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0129fa;
}

.deparymentsAccessCard .departmentCards span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #828282;
}

.deparymentsAccessCard .departmentCards p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.accessControllCheckIn h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

.accessControllCheckIn p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #828282;
}

.accessControlPage .accessControllCheckIn span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #828282;
}

.accessControlPage .green {
  border-radius: 4px;
  border: none;
  border-bottom: 4px solid #35d7a1;
}

.accessControlPage .red {
  border-radius: 4px;
  border: none;
  border-bottom: 4px solid #ef4050;
}

.accessControlPage .checkinCards h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.accessControlPage .checkinCards span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #828282;
}

.vertical-hr {
  border: 1px solid rgba(130, 130, 130, 0.15); /* Adjust the color and size of the line as needed */
  height: 100%; /* Adjust the height of the line as needed */
}

.nonActiveSideBtn {
  border-radius: 4px;
  height: 48px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(130, 130, 130, 0.15);
  --bs-btn-hover-bg: none;
  --bs-btn-hover-border-color: none;
  --bs-btn-hover-color: none;
  --bs-btn-focus-shadow-rgb: none;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.activeSideBtn {
  border-radius: 4px;
  height: 48px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  background: #0129fa;
  border: none;
  --bs-btn-hover-bg: #0129fa;
  --bs-btn-hover-border-color: none;
  --bs-btn-focus-shadow-rgb: none;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.accessLevelIcon {
  margin-right: 10px;
}

.acitveSideBar {
  background: #0129fa;
  border: none;
}

.nonAcitveSideBar {
  background: #ffffff;
  border: 1px solid rgba(130, 130, 130, 0.15);
}

.accessSettingCard {
  border: none;
  height: 90vh;
}

.settingsTabContainer {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  justify-content: space-between;
  height: 110px;
  border-radius: 4px;
}

.settingsTabContainer .acitveSideBar h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
.settingsTabContainer .nonAcitveSideBar h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}

.departmentCardWidth {
  width: 100%;
  overflow-x: auto;
}

.accessTimeControlComponent .accessTimeHeader h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.accessTimeControlComponent .accessTimeHeader p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #828282;
}

.accessCardsContainer {
  height: 80vh;

  white-space: nowrap;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: auto; /* Enable vertical scrolling */
}

.accessTimeControlComponent .departmentCards h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0129fa;
}

.accessTimeControlComponent .departmentCards span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #828282;
}
.accessTimeControlComponent .departmentCards p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.accessSettingSideTabs {
  display: flex;
  height: 85vh;
}

.accessTimeListContainer {
  padding-left: 20px;
}

.AccessSettingBtnResponsive {
  width: 75%;
}

.shimmerCard {
  height: 80px;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 900px) {
  .departmentCards {
    width: 100vh;
  }
}

@media only screen and (max-width: 780px) {
  .checkinContainer {
    margin-top: 20px;
    padding: 5px;
  }

  .accessSettingCard {
    height: 100%;
  }

  .accessSettingSideTabs {
    height: 100%;
  }

  .settingsTabContainer {
    margin-right: 0px;
    margin-bottom: 30px;
    padding-bottom: 30px;

    /* flex-direction: row; */
    justify-content: space-between;
    height: 100%;
  }

  .AccessSettingBtnResponsive {
    width: 100%;
  }

  .vertical-hr {
    display: none;
  }

  .accessTimeListContainer {
    padding-left: 0;
  }

  .deparymentsAccessCard {
    margin-right: 0px;
    border: none;
  }

  .nonActiveSideBtn {
    font-size: 12px;
    white-space: nowrap;
  }
  .activeSideBtn {
    font-size: 12px;
    white-space: nowrap;
  }
}
