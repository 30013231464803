.lg-btn1 {
  border-radius: 4px;
  background-color: white;
  border: 1px #cacaca solid;
  height: 45px;
  width: 145px;
  font-weight: 600;
}
/* .lg-btn2 {
  border-radius: 4px;
  background-color: #000000;
  border: none;
  height: 45px;
  font-weight: 600;
  width: 145px;
} */

.lglogin {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  margin-top: 15psx;
}
.requestBody {
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 550;
}
.requestBody span {
  font-size: 25px;
  color: #000000;
  font-weight: 600;
}
.requestBody p {
  color: #000000;
  font-size: 18px;
  margin-left: 10px;
}
.approval-btn {
  margin-left: 15px;
}

.cardHeader {
  display: flex;
  justify-content: center;
}
.cardBody {
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .model-text {
    white-space: nowrap;
  }
}
