.rejection-reason-model {
  padding-top: 24px;
  padding-bottom: 24px;
}
.reason-model h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
}
.reason-model p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}
.reason-model {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-add-reason {
  background: rgba(202, 202, 202, 0.1);
  border-radius: 10px;
  border: none;
  height: 40px;
  width: 100%;
}

.col-input-add-reason {
  padding-left: 33px;
  padding-right: 40px;
}

.rejection-model-btn1 {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 48px;
  color: #000000;
}
.rejection-model-btn2 {
  border: none;
  width: 100%;
  height: 48px;
  background: #000000;
  border-radius: 4px;
}

.rejection-btn1-col {
  padding-right: 10px;
}
.rejection-btn2-col {
  padding-left: 10px;
}

@media only screen and (max-width: 600px) {
  .col-input-add-reason {
    padding-left: 0px;
    padding-right: 0px;
  }

  .reason-model h5 {
    font-size: 20px;
  }
  .reason-model p {
    font-size: 14px;
  }
}
