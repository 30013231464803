.ms_Container {
  display: flex;
  justify-content: center;
}
.ms_Card {
  background: #ffffff;
  border-radius: 4px;
  border: none;
}
.addNewBtn {
  height: 48px;
  background: #000000;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
}
.ms_Container p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
.mts_main_bg {
  background: #f7f7f8;
}
.mts_required_star {
  color: #ef4050;
  display: inline-flex;
}
.mts_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #000000;
}
.mts_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #000000;
}
.mts_h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
.drop_logo_center {
  text-align: center;
  align-items: center;
}
.mts_card_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #000000;
}
.mts_card_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.01em;
  color: rgba(0, 0, 0, 0.5);
}
.nextBtn {
  height: 48px;
  margin-right: 20px;
  background: #ffffff;
  border-radius: 4px;
  font-family: "Montserrat";
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  --bs-btn-hover-bg: none;
  --bs-btn-hover-color: none;
}
.flexBtn {
  height: 48px;
  background: #ffffff;
  border-radius: 4px;
  font-family: "Montserrat";
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  --bs-btn-hover-bg: none;
  --bs-btn-hover-color: none;
}
.resource_accordation {
  background: #f6f6f6 !important;
}
.accordion-button {
  background: #f6f6f6 !important;
}
.mts_main_bg .dropDown .accordion-button {
  background: #ffffff !important;
}
.mts_main_bg .dropDown .accordion-item {
  border: none !important;
}
.mts_main_bg .dropDown .accordion-button:after {
  /* height: 6.15rem; */
}
.resource_acc_h1 {
  display: inline-flex;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
}
.resource_acc_h2 {
  display: inline-flex;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}
.accordation_row {
  display: inline-flex;
  align-items: center;
}
.dropdown_placeholder {
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: inline-flex !important;
  color: #1c1b1f;
}
.accordation_h1_office {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #0129fa;
}
.accordation_h2_office {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #000000;
}
.accordation_h3_office {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #000000;
}
.add-btn1 {
  padding: 0 7px 0 7px;
}
.deleteRescorce {
  float: right;
}
.remove_css {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #000000;
}
.add_source_css {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-left: 10px;
}
.annualy_text {
  color: #0129fa;
}
.monthly_duration {
  background: none;
  border-left-color: #0129fa;
}
.edit_button_align {
  float: right;
}
.month_payment_btns {
  background: rgba(1, 41, 250, 0.03) !important;
  border: 1px solid rgba(1, 41, 250, 0.2) !important;
}
.month_payment_btn_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #0129fa;
}
.month_payment_btn_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  display: inline-flex;
  color: rgba(1, 41, 250, 0.5);
}
.private_Office_occor {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.private_Office_occor1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #f05f23;
}
/* //private Office = p_o */
.p_o_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0129fa;
}
.p_o_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}
.p_o_h11 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* font-size: 0.75em; */
  color: rgba(0, 0, 0, 0.5);
}
.p_o_h_padding {
  padding-left: 5px;
  padding-right: 5px;
}
.yearlyactiveBtn {
  background: #ffffff;
  border: 1px solid black;
}
.monthlyactiveBtn {
  background: #ffffff;
  border: 1px solid black;
}
.slotIncativeMonths {
  border: 1px solid #b0b0b0a7;
}
.inacBtn {
  height: 48px;
  margin-right: 20px;
  background: #ffffff;
  border-radius: 4px;
  /* border: 1px solid #b0b0b0a7; */
  font-family: "Montserrat";
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  --bs-btn-hover-bg: none;
  --bs-btn-hover-color: none;
}
.flex_hours_css {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #ef4050;
}
.select_m_dropdown .css-13cymwt-control {
  height: 48px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  --bs-btn-hover-bg: none;
  --bs-btn-hover-color: none;
}
/* // membership details = m_d  */
.m_d_name_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #000000;
}
.m_d_name_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 0, 0, 0.5);
}
.so_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #000000;
}
.so_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #000000;
}
.so_total_users {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #0129fa;
}
.pos_cardusersicon {
  justify-content: space-between;
}
.so_total_hh2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: -0.01em;
  color: rgba(0, 0, 0, 0.5);
}
.so_cc_main {
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.link_line {
  text-decoration: none;
}
.textSizeAdjust {
  /* text-size-adjust: "auto" !important; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
