.dbNavBar {
  height: 80px;
  /* margin-left: 20px; */
  flex-wrap: inherit !important;
  margin: 3px;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* overflow: hidden; */
  /* position: sticky;
  top: 0;
  width: 100%; */
}

.homeBreadCrum {
  background-color: #e2e2e2;
  align-items: center;
  margin-left: 20px;
  color: #000000;
  padding: 5px;
  /* height: 33px; */
  border-radius: 0 10px 10px 0;
  text-transform: capitalize;
  font-family: "Montserrat";
  justify-content: center;
  align-items: center;
}

.dbNavRoutes {
  background-color: #e2e2e2;
  align-items: center;
  margin-left: 10px;
  color: #000000;
  padding: 5px;
  border-radius: 0 10px 10px 0;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: capitalize;
  font-family: "Montserrat";
  justify-content: center;
  align-items: center;
}
.nav1 {
  margin-left: 10px;
}

.dbNavIcons {
  margin-right: 35px;
}
.notificationIcon {
  margin-right: 20px;
}
.dbNav-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.profile {
  background-color: #000000;
  border: none;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-btn {
  background: none;
  /* color: #cccccc; */
  color: #172659;
  border: none;
  display: none;
  /*  */

  align-items: center;
  margin: 10px;
  /* padding: 5px; */
  /* height: 33px; */
  justify-content: center;
  align-items: center;
}
.sidebtn {
  display: none;
}

.sideIconsContainer {
  width: 150px;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 1200px) {
  .sidebtn {
    display: block;
  }
  .dbNavIcons {
    margin: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .sideIconsContainer {
    width: 150px;
  }

  .homeBreadCrum {
    font-size: 10px;
    margin: 5px;
  }
  .dbNavRoutes {
    font-size: 10px;
    margin: 5px;
  }
  .dbNav-icon {
    width: 20px;
    height: 20px;
  }

  .dbNavIcons {
    margin: 10px;
  }
  .notificationIcon {
    margin-right: 0px;
  }
  .dbNavBar {
    height: 60px;
  }
  .back-btn {
    display: block;
  }
  .sidebtn {
    display: block;
  }
  .nav-tab {
    display: none;
  }
  .tab-show {
    display: block !important;
  }
}
