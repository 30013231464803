.checkinCardsText {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
}

.contaier {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkInLogContaier {
  border: none;
  border-radius: 8px;
}

.checkInLogContaier .failedCheckInCard {
  background: rgba(239, 64, 80, 0.05);
  border: 1px solid rgba(239, 64, 80, 0.5);
  border-radius: 8px;
}

.checkInLogContaier .failedCheckInCard h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ef4050;
}

.checkInLogContaier .successCheckInCard h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #35d7a1;
}

.checkInLogContaier {
  min-height: 75vh;
}

.checkInLogContaier .checkInLogsCard h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #828282;
}
.accessLevelControlCard {
  height: 80vh;
  overflow-y: auto;
}

.checkInLogContaier .checkInLogsCard p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
