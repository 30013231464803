.profileCard {
  border-radius: 4px;
}

.profileCard h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000000;
}

.profileCard span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-decoration-line: underline;
  color: #000000;
}

.profileCard h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.profileField {
  height: 48px;
  background: #ededed;
  border-radius: 4px;
  border: none;
  color: #808080;
}
