.tours-setting-card {
  margin-top: 27px;
  margin-left: 35px;
  margin-right: 40px;
  padding-top: 31px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  background: #000000;
  /* Official Grey */

  border: 2px solid #000000;
  border-radius: 35px;
}

select option:hover {
  background-color: #000000 !important; /* change this to whatever color you want */
}

.close-img {
  cursor: pointer;
}
.tourSlotDiv {
  display: flex;
  justify-content: space-between;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: #000000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #000000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #000000;
}

input:checked + .slider:before {
  background-color: white;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

.line-break {
  margin-bottom: 35px;
}

.tours-setting-header h3 {
  /* margin-top: 25px; */
  margin-bottom: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
.tours-setting-header p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 0px;
  color: #808080;
}

.tourDuration {
  margin-bottom: 40px;
  border: none;
  background-color: #fafafa;
  border-radius: 4px;
  height: 48px;
}
.toggleExpand {
  display: flex;
  justify-content: end;
}

.toggleStyle {
  display: flex;
  justify-content: center;
  background-color: rgba(130, 130, 130, 0.1);
  border-radius: 6px;
  width: 32px;
}

/* input[type="select"] option:hover {
  background-color: #000000;
} */

.tours-btn-div {
  float: right;
}

select:focus > option:checked {
  background: #444645 !important;
  color: #ffffff;
}

select:focus > option:hover {
  background: #000 !important;
  color: #ffffff;
}

option:hover {
  color: #444645;
  background: #ddd;
}

.tours-add-slot-btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 48px;
  background-color: #000000;
  line-height: 1;
  /* Official Saudi Green */
  --bs-btn-hover-bg: #000000;
  border: none;
  border-radius: 4px;
  --bs-btn-focus-shadow-rgb: none;
}

.tours-remove-slot-btn {
  height: 48px;
  margin-right: 20px;
  background: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  line-height: 1;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  --bs-btn-hover-border-color: none;
  --bs-btn-hover-bg: none;
  --bs-btn-hover-color: none;
  --bs-btn-focus-shadow-rgb: none;
}

.calender-table th {
  padding: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
}
.calender-table {
  border-collapse: separate;
  border-spacing: 15px;
  width: 100%;
}

.calender-table td {
  white-space: nowrap;
  background-color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  color: #000000;
  font-size: 12px;
  padding: 15px;
  width: 100px;
  height: 47.19px;
  border: 1px solid #000000;
  border-radius: 4px;
}
.calender-table td img {
  padding-left: 10px;
}
.active-tabledata {
  border: none !important;
}

.calender-card {
  width: 100%;
  margin-top: 15px;
  background: #ededed;
  border: none;
  border-radius: 4px;
}

.tours-setting-btn {
  display: block;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  --bs-btn-hover-bg: #000000;
  background: #000000;
  border-radius: 4px;
  height: 48px;
  border: none;
}
.tours-setting-hide-btn {
  display: none;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.1;
}

.custom-select {
  height: 48px;
}
.holidayCard {
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.holidayCard h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}
.holidayCard p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.holidayCardCol {
  margin-right: 10px;
}

@media only screen and (max-width: 1300px) {
  .calender-card {
    overflow: auto;
  }

  .tours-setting-card {
    margin-top: 27px;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 31px;
    padding-left: auto;
    padding-right: auto;
    padding-bottom: 40px;
  }
}
